'use client';

import {notificationEmailApi} from 'modules/api/client';
import useNotification from 'modules/notification/useNotification';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import {IFormData} from 'components/page/leadgeneration/interfaces/IFormData';
import locations from 'components/page/search/map/config/locations';

interface IReturn {
    onSubmit: (data: IFormData) => Promise<void>,
}


export const useForm = (onFinish: () => void): IReturn => {

    const {error: showErrorNotification} = useNotification();
    const { gtm: { sendEvent: gtmSendEvent }} = useAnalytics();

    let utmCampaign = '';
    if (typeof window !== 'undefined') {
        utmCampaign = new URLSearchParams(window.location.search).get('utm_campaign');
    }

    const onSubmit = async (data: IFormData) => {
        const selectedLocation = locations.find(location => location.name === 'okres '+data.district);
        data.location.latitude = selectedLocation.geoPoint.lat;
        data.location.longitude = selectedLocation.geoPoint.lon;

        try {
            const recaptchaToken = data.recaptchaToken;
            const response = await notificationEmailApi.sendEmailFromLeadgenerationPage(
                {
                    name: data.name,
                    email: data.email,
                    phoneNumber: data.phoneNumber,
                    type: data.type,
                    district: data.district,
                    agreeToTerms: data.agreeToTerms,
                    agreeToGDPR: data.agreeToGDPR,
                    usableArea: data.usableArea,
                    location: data.location,
                    ...(utmCampaign && { utmCampaign }),
                },
                {
                    headers: {
                        'recaptcha-token': recaptchaToken,
                        'recaptcha-type': 'tickbox',
                    }
                }
            );

            if (response.status !== 201) {
                throw new Error(`Response status was: ${response.status}`);
            }

            gtmSendEvent({event: 'track-event', category: 'lead-gen', action: 'odoslany-formular'});
            onFinish();
        } catch (error) {
            showErrorNotification('Nepodarilo sa odoslať kontaktný formulár');
        }
    };

    return {
        onSubmit
    };
};
